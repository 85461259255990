import { EditorProps as ReactDraftEditorProps } from 'react-draft-wysiwyg'
import dynamic from 'next/dynamic'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { isJson } from '@gruene-brise/data-access/lib/utils'

const ReactDraftEditor = dynamic<ReactDraftEditorProps>(
  () => import('react-draft-wysiwyg').then((module) => module.Editor),
  { ssr: false }
)

interface EditorProps extends ReactDraftEditorProps {
  label?: string
  labelClassName?: string
  initialContent?: string
  onStateChange?: (JSONState: string) => void
}

const Editor: React.FC<EditorProps> = ({
  editorState,
  label,
  labelClassName,
  initialContent,
  onStateChange,
  ...props
}) => {
  const [state, setState] = useState<EditorState>(EditorState.createEmpty())

  useEffect(() => {
    if (initialContent) {
      const content = isJson(initialContent)
        ? JSON.parse(initialContent)
        : {
            blocks: [
              {
                key: '35vdv',
                text: initialContent,
                type: 'unstyled',
                depth: 0,
                inlineStyleRanges: [],
                entityRanges: [],
                data: {}
              }
            ],
            entityMap: {}
          }
      setState(EditorState.createWithContent(convertFromRaw(content)))
    }
  }, [initialContent])

  const onEditorStateChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent()
    setState(editorState)
    const convertedStateToJSON = JSON.stringify(convertToRaw(contentState))
    onStateChange?.(convertedStateToJSON)
  }

  return (
    <div>
      {label && (
        <label
          className={`block text-gray-700 text-[10px] lg:text-xs md:text-xs xl:text-xs font-gellix font-medium mb-2 ml-1 ${
            labelClassName || ''
          }`}
        >
          {label}
        </label>
      )}

      <ReactDraftEditor
        toolbarClassName={styles['toolbar']}
        wrapperClassName={styles['wrapper']}
        editorClassName={styles['editor']}
        editorState={state}
        onEditorStateChange={onEditorStateChange}
        {...props}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'link',
            'embedded',
            'emoji',
            'remove',
            'history'
          ]
        }}
      />
    </div>
  )
}

export default Editor
