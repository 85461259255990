import { Spinner } from '@chakra-ui/react'
import {
  CheckoutProductInput,
  FilterComparator,
  NcmProduct,
  ProviderOffer
} from '@gruene-brise/data-access/lib/api/generated'
import { useDebounce } from '@gruene-brise/data-access/lib/hooks/useDebounce'
import useInfinitePublicAvailableOffer from '@gruene-brise/data-access/lib/hooks/useInfinitePublicAvailableOffer'
import useInfiniteScrollObserver from '@gruene-brise/data-access/lib/hooks/useInfiniteScrollObserver'
import useQuantity from '@gruene-brise/data-access/lib/hooks/useQuantity'
import { isEmpty } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import FormButton from '../form/FormButton'
import PharmacyCartItem from './PharmacyCartItem'

export interface SelectPharmacyProps {
  products: NcmProduct[]
  onPress?(id: string, products?: CheckoutProductInput[] | NcmProduct[]): void
  search?: string
  hideModal?: boolean
  onHasPharmacy?(e: boolean): void
  selectedPharmacy?: string
  hideContactPharmacy?: boolean
  setLowestPrice?(e: number): void
  setIsLoading?(e: boolean): void
}

const SelectPharmacy = ({
  products,
  onPress,
  search,
  hideModal,
  selectedPharmacy,
  onHasPharmacy,
  hideContactPharmacy,
  setLowestPrice,
  setIsLoading
}: SelectPharmacyProps) => {
  const moreRef = useRef()
  const { t } = useTranslation()
  const getQuantity = useQuantity()

  const productsValue = (products ?? [])
    .map((i) => ({
      id: i.product.id,
      quantity: getQuantity({
        quantity: i?.quantity,
        unitValue: i.product.unitValue
      })
    }))
    .filter((i) => i.quantity > 0)

  const {
    data: productsOffer,
    hasNextPage,
    fetchNextPage,
    isLoading
  } = useInfinitePublicAvailableOffer({
    input: {
      products: productsValue,
      filter: {
        filter: isEmpty(search ?? '')
          ? (undefined as any)
          : {
              nodes: [
                {
                  comparator: FilterComparator.In,
                  field: 'pharmacyName',
                  value: search
                }
              ]
            }
      }
    }
  })

  useEffect(() => {
    setIsLoading?.(isLoading)
  }, [isLoading, setIsLoading])

  useEffect(() => {
    setLowestPrice?.(productsOffer?.pages[0]?.lowestPrice ?? 0)
  }, [productsOffer?.pages, setLowestPrice])

  useInfiniteScrollObserver({
    condition: hasNextPage!,
    reference: moreRef.current,
    onAction: () => {
      try {
        fetchNextPage()
        // eslint-disable-next-line no-empty
      } catch {}
    }
  })

  const productOfferArray = useMemo(
    () =>
      productsOffer?.pages ? productsOffer.pages.flatMap((i) => i.offers) : [],
    [productsOffer?.pages]
  )

  const [debounceProductOfferArray] = useDebounce(productOfferArray, 1000)

  const [isDebounceLoading] = useDebounce(isLoading, 1000)

  useEffect(() => {
    ;(() => {
      onHasPharmacy?.(debounceProductOfferArray.length > 0)
    })()
  }, [debounceProductOfferArray])

  return (
    <div className="w-full">
      {isDebounceLoading && (
        <div className="w-full flex flex-row items-center justify-center">
          <Spinner size={'lg'} />
        </div>
      )}

      {productOfferArray.map((providerOffer) => {
        return (
          <PharmacyCartItem
            hideModal={hideModal}
            selectedPharmacy={selectedPharmacy}
            key={providerOffer.id}
            lowestPrice={productsOffer?.pages[0]?.lowestPrice}
            {...(onPress
              ? {
                  onPress: (id) => onPress?.(id, productsValue)
                }
              : {})}
            {...(providerOffer as ProviderOffer)}
          />
        )
      })}

      {(productOfferArray ?? []).length <= 0 &&
        !isDebounceLoading &&
        !hideContactPharmacy && (
          <div className="w-[93%] p-5 my-1 mx-3  shadow-md cursor-pointer rounded-2xl font-gellix bg-white">
            <div className=" font-light w-[90%] tex-xs">
              <Trans>
                Unfortunately, pharmacies are not available for selected
                products. Please contact us directly to request a pharmacy
              </Trans>
            </div>

            <div className="mt-5 w-full px-5">
              <FormButton
                title={t('Contact Grüne-Brise Team')!}
                className="text-xs font-semibold"
                style={{ height: 32 }}
                onPress={() => {
                  ;(window as any)?.HubSpotConversations?.widget?.open?.()
                }}
              />
            </div>
          </div>
        )}

      <div className="h-[200px] w-full relative" ref={moreRef as any} />
    </div>
  )
}

export default SelectPharmacy
