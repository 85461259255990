import { Product, PublicProduct } from '../api/generated'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'

import { persist } from './persist'
import { isEmpty, isUndefined, merge } from 'lodash'

export interface ProductSelectionItem {
  id: string
  selection: number
  product: PublicProduct
  addedAt: Date
}

export type ProductSelection = {
  [k: string]: ProductSelectionItem
}

export const getAmount = (product: ProductSelectionItem) => {
  return Number(product.product.startingPrice) * product.selection
}

const defaultState: {
  isCartOpen?: boolean
  products: ProductSelection
  total: number
  totalItems: number
} = {
  isCartOpen: false,
  products: {} as ProductSelection,
  total: 0,
  totalItems: 0
}

/**
 * Store manager for cart management
 */
export const useCartState = create(
  persist(
    {
      key: 'cart9'
    },
    combine(defaultState, (set) => ({
      setCartOpen: (isCartOpen: boolean) =>
        set((state) => ({ ...state, isCartOpen })),
      addProduct: (product: ProductSelectionItem) =>
        set((state) => {
          const value = { ...state.products[product.id] }

          if (isUndefined(value) || isEmpty(value)) {
            state.total += getAmount(product)
            state.totalItems += 1
          } else {
            if (value.selection !== product.selection) {
              state.total += -getAmount(value) + getAmount(product)
            }
          }

          state.products = {
            ...state.products,
            [product.id]: {
              ...product
            }
          }

          return {
            ...state
          }
        }),
      clearProduct: () =>
        set((state) => ({ ...state, products: {}, isCartOpen: false })),
      removeProduct: (product: ProductSelectionItem) =>
        set((state) => {
          state.total = Math.max(state.total - getAmount(product), 0)
          state.totalItems -= 1
          const products = state.products
          delete products[product.id]

          return { ...state }
        })
    }))
  )
)
