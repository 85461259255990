import { AddableOptionsProps } from '@gruene-brise/common-ui/lib/AddableDropdown/AddableOptions'
import { ValueProps } from '@gruene-brise/common-ui/lib/Dropdown/types'
import {
  FilterComparator,
  FilterLabelInput
} from '@gruene-brise/data-access/lib/api/generated'
import { useGraphql } from '@gruene-brise/data-access/lib/hooks/useGraphql'
import { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
const useProductLabel = ({ type }: Pick<AddableOptionsProps, 'type'>) => {
  const [options, setOptions] = useState<ValueProps[]>([])
  const { labels } = useGraphql()
  const filterLabels = useMutation((input: FilterLabelInput) =>
    labels({ input })
  )
  const isLoading = filterLabels.isLoading

  const fetchProductLabel = useCallback(
    async (search?: string) => {
      const filter = {
        field: 'namespace',
        value: `products.${type}`,
        comparator: FilterComparator.Eq
      }

      const nodes = search
        ? [
            filter,
            {
              field: 'name',
              value: `${search}`,
              comparator: FilterComparator.Like
            }
          ]
        : [filter]

      const { labels } = await filterLabels.mutateAsync({
        filter: {
          filter: {
            nodes: nodes
          },
          pagination: {
            pageSize: 20
          }
        }
      })
      const options =
        labels?.results?.map((result) => ({
          id: result.id.toString(),
          value: result.name,
          label: result.name
        })) || []
      setOptions(options)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [type]
  )

  useEffect(() => {
    fetchProductLabel()
  }, [])
  return { fetchProductLabel, isLoading, options }
}

export default useProductLabel
