import { useMediaQuery } from '@chakra-ui/react'
import ProductCard, {
  ProductCardProps
} from '@gruene-brise/common-ui/lib/webshop/ProductCard'
import ProductCardModal from '@gruene-brise/common-ui/lib/webshop/ProductCardModal'
import {
  FilterExpression,
  Sorting
} from '@gruene-brise/data-access/lib/api/filter'
import useCartManager from '@gruene-brise/data-access/lib/hooks/useCartManager'
import useInfinitePublicProduct from '@gruene-brise/data-access/lib/hooks/useInfinitePublicProduct'
import useInfiniteScrollObserver from '@gruene-brise/data-access/lib/hooks/useInfiniteScrollObserver'
import { ProductFilterFields } from '@gruene-brise/data-access/lib/types'
import { PublicProduct } from '@gruene-brise/data-access/lib/api/generated'
import { useCartState } from '@gruene-brise/data-access/lib/state/useCartState'
import { useChristmasToastState } from '@gruene-brise/data-access/lib/state/useChristmasToastState'
import { isUndefined } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FadeLoader } from 'react-spinners'
import Cart from '@gruene-brise/common-ui/lib/webshop/Cart'
import ProductSearchAndFilter from './ProductFilter'
import useAuth0 from '@gruene-brise/data-access/lib/hooks/useAuth0'
import { toast } from 'react-toastify'
interface Props {
  /**
   * Will override the search query from the url
   */
  search?: string
}

/**
 * LiveBestand has a list of all the items avialable for user purchase
 * @returns JSX.Element
 */
const LiveBestand = ({ search }: Props) => {
  const [filters, setFilters] = useState<{
    filters?: FilterExpression<ProductFilterFields>
    sort?: Sorting<ProductFilterFields>[]
    search?: string
    pharmacyId?: string
  }>({})

  const filterByPharmacyId = filters?.pharmacyId
    ? {
        pharmacyId: filters.pharmacyId
      }
    : {}

  const {
    data: products,
    hasNextPage,
    fetchNextPage,
    isLoading
  } = useInfinitePublicProduct({
    input: {
      filter: {
        filter: filters.filters,
        sort: filters.sort
      },
      ...(filters.search ? { search: filters.search } : {}),
      ...filterByPharmacyId
    }
  })

  const { t } = useTranslation()
  const [, setShowOverlay] = useState(false)
  const productArray = (products?.pages?.map((i) => i.results).flat() ??
    []) as PublicProduct[]
  const { isCartOpen } = useCartState()
  const moreRef = useRef()
  const { setToastShown, toastShown } = useChristmasToastState()

  useInfiniteScrollObserver({
    condition: !!hasNextPage,
    reference: moreRef.current,
    onAction: () => fetchNextPage()
  })

  useEffect(() => {
    const newYear = new Date('2023-12-31T00:00:00')
    const now = new Date()
    now.setHours(0, 0, 0, 0)

    if (toastShown === false && now < newYear) {
      toast(
        t(
          'Please make sure to send in your prescription and pay your orders before 11 December so your parcel can reach you prior to holidays. Please also note that due to longer delivery times your prescription needs more time by post.'
        ),
        {
          autoClose: false,
          type: 'warning',
          style: { color: 'black' },
          theme: 'colored',
          bodyClassName: 'text-sm text-primary',
          toastId: 'christmasToast',
          className: 'christmas-toast'
        }
      )
      setToastShown(true)
    }
  }, [])

  const [isNotSmallScreen] = useMediaQuery('(min-width: 766px)')
  return (
    <div className="w-full mb-10 ">
      <div className=" w-full h-full flex overflow-hidden">
        <div className="w-full h-screen">
          <div
            className={`fw-full overflow-y-scroll h-full  bg-primary-25 ${
              isCartOpen ? 'scrollbar-hide' : ''
            }`}
          >
            <div className={`w-full relative`}>
              <div className="w-full relative pt-7">
                <ProductSearchAndFilter
                  search={search}
                  setFilters={setFilters as (val: unknown) => void}
                  onDropdownActive={(val: boolean) => setShowOverlay(val)}
                />
              </div>
            </div>

            {/* THIS HAS A FIXED HEIGHT NOW*/}

            <div
              className={`w-full items-center flex flex-col z-50 mt-2 md:mt-4`}
            >
              <div className="h-[30px] w-full" />

              {(productArray ?? []).length <= 0 && !isLoading && (
                <div className="h-[300px] w-full items-center flex flex-col justify-center font-gellix gap-2">
                  <div className="text-primary-25 font-bold text-lg">
                    {t('No result to show')}
                  </div>
                  <div className="text-primary font-400 text-sm">
                    {t(
                      'Try adjusting your search to find what you are looking for.'
                    )}
                  </div>
                </div>
              )}

              <div className="xl:max-w-[1400px] lg:max-w-[1200px] md:max-w-[1000px] justify-center flex flex-col items-center">
                <div
                  className={`w-[94%] xl:w-[87%] self-center gap-3 xl:gap-5 lg:gap-3 md:gap-3 grid ${
                    isCartOpen && isNotSmallScreen
                      ? 'grid-cols-2 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2'
                      : 'grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3'
                  }`}
                >
                  <ProductCardList productArray={productArray ?? []} />
                </div>
              </div>
            </div>

            <div className="h-[500px] w-full relative" ref={moreRef} />

            {isLoading && (
              <div className="fixed top-0 w-screen h-screen justify-center items-center opacity-[0.3] z-50 bg-primary">
                <div className="w-full h-full flex items-center justify-center">
                  {isLoading && <FadeLoader color="#fff" />}
                </div>
              </div>
            )}
          </div>
        </div>
        <Cart drawerFeature={false} />
      </div>
    </div>
  )
}

export default LiveBestand

const ProductCardList = ({
  productArray
}: {
  productArray: PublicProduct[]
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState<ProductCardProps>()
  const { isItemAlreadyInCart } = useCartManager()
  const { isDoctor } = useAuth0('webshop')

  const onAddToCart = (i: ProductCardProps) => {
    if (isItemAlreadyInCart(i.id)) return
    setSelectedItem(i)
    setOpenModal(true)
  }

  return (
    <>
      {productArray.map((i) => {
        return (
          <ProductCard
            key={i.id}
            showCartButton={!isDoctor}
            {...i}
            productCardWrapperClass="!mb-5 sm:!mb-10"
            onPress={() => {
              onAddToCart(i)
            }}
          />
        )
      })}

      {!isUndefined(selectedItem) && (
        <ProductCardModal
          onClose={() => {
            setOpenModal(false)
          }}
          {...selectedItem}
          visibility={openModal}
        />
      )}
    </>
  )
}
