import environment from '@gruene-brise/data-access/lib/config/environment'
import useCartManager from '@gruene-brise/data-access/lib/hooks/useCartManager'
import useCurrencyFormatter from '@gruene-brise/data-access/lib/hooks/useCurrencyFormatter'
import { ProductSelectionItem } from '@gruene-brise/data-access/lib/state/useCartState'
import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { BsX } from 'react-icons/bs'
import usePopUpModal from '../Modal'
import AddCartControl from './AddCartControl'
import usePrice from '@gruene-brise/data-access/lib/hooks/usePrice'
import { useStatusTranslations } from '@gruene-brise/data-access/lib/hooks/useStatusTranslations'
import { useQuantityUnitValue } from '@gruene-brise/data-access/lib/hooks/useQuantity'

export interface ProductCartItemProps extends ProductSelectionItem {
  showPriceDescription?: boolean
  showCancel?: boolean
  short?: boolean
  notEditabled?: boolean
  showButtons?: boolean
  input?: {
    fullWidth?: boolean
  }
}

const ProductCartItem = (productSelectionItem: ProductCartItemProps) => {
  const {
    product: {
      startingPrice,
      startingPriceIsInsurance,
      fullName,
      imageURL,
      strain,
      unit: unitItem,
      unitValue,
      basePrice
    },
    selection,
    id,
    showPriceDescription,
    showCancel = true,
    short = true,
    showButtons = true,
    notEditabled,
    input
  } = productSelectionItem
  const fullWidth = input?.fullWidth ?? false

  const { removeProductFromCart, addProductToCart } = useCartManager()
  const numberFormat = useCurrencyFormatter()
  const { calculatePrice } = usePrice()
  const priceFormat = numberFormat(
    calculatePrice(
      { startingPrice, startingPriceIsInsurance, unitValue, basePrice },
      selection
    )
  )

  const { productUnitTranslation } = useStatusTranslations()

  const unit = productUnitTranslation(unitItem)
  const maxUnitValue = environment.maximumNumberOfCartItem

  const { ConfirmationModal, open, close } = usePopUpModal()
  const getQuantityUnitValue = useQuantityUnitValue()
  const { t } = useTranslation()

  return (
    <div className="w-full p-3 font-gellix">
      <div className="w-full justify-between flex flex-row">
        <Link href={`/product/${id}`} className="w-[80%] flex flex-row">
          <div className="">
            <img src={imageURL} className="w-[60px] h-[50px]" alt="" />
          </div>
          <div className="ml-2">
            <div className="line-clamp-2 text-sm md:text-md xl:text-md lg:text-md font-semibold">
              {fullName}
            </div>
            <div className="text-primary-50 text-[10px] font-medium uppercase w-[90%] line-clamp-2">
              {strain?.name}
            </div>
          </div>
        </Link>
        {showCancel && (
          <div className="">
            <div
              className="w-[25px] h-[25px] items-center flex justify-center rounded-full bg-primary-alpha cursor-pointer"
              onClick={() => {
                open()
              }}
            >
              <BsX strokeWidth={0.5} size={18} className="text-primary" />
            </div>
          </div>
        )}
      </div>
      <AddCartControl
        onClickMinus={() => {
          addProductToCart({
            ...productSelectionItem,
            selection:
              selection - unitValue <= 0 ? selection : selection - unitValue
          })
        }}
        onClickPlus={() => {
          addProductToCart({
            ...productSelectionItem,
            selection:
              selection + unitValue > maxUnitValue
                ? selection
                : selection + unitValue
          })
        }}
        price={priceFormat}
        priceDescription={
          startingPriceIsInsurance
            ? ''
            : getQuantityUnitValue({
                unitValue,
                unit: unitItem,
                basePrice,
                startingPrice
              })
        }
        showButtons={showButtons}
        showPriceDescription={showPriceDescription}
        gram={selection}
        unit={unit}
        unitValue={unitValue}
        canEdit={notEditabled}
        setGram={(e) => {
          addProductToCart({
            ...productSelectionItem,
            selection: e
          })
        }}
        short={short}
        fullWidth={fullWidth}
      />

      <ConfirmationModal
        className="w-[400px] md:w-[600px] lg:w-[600px] xl:w-[600px]"
        actionButtonOption={{
          title: t('Yes, continue')!,
          onPress: async (e) => {
            e.preventDefault()
            removeProductFromCart(productSelectionItem)
            close()
          }
        }}
        title={`${t('Are you sure you want to remove product?')}`}
        warningMessage={t('Please confirm that you would like to continue.')!}
      />
      <div className="mt-3 h-[1px] bg-primary-10 w-full" />
    </div>
  )
}

export default ProductCartItem
