import environment from '@gruene-brise/data-access/lib/config/environment'
import { useTranslation } from 'react-i18next'
import { BiMinus } from 'react-icons/bi'
import { BsPlus } from 'react-icons/bs'
import QuantityError from './QuantityError'
import classNames from 'classnames'

export interface AddCartProps {
  onClickMinus?(): void
  onClickPlus?(): void
  price?: string
  priceDescription?: string
  unit?: string
  gram?: number
  showPriceDescription?: boolean
  showPrice?: boolean
  short?: boolean
  setGram(e: number): void
  canEdit?: boolean
  bgColor?: string
  showButtons?: boolean
  unitValue?: number
  fullWidth?: boolean
}

/**
 * AddCartControl shows a minus or plus control for increasing or decreasing the quantity of a product
 *
 * @param onClickMinus
 * @param onClickPlus
 * @param price
 * @param priceDescription
 * @param unit
 * @param gram
 * @param setGram
 * @param short
 * @param showPriceDescription
 * @param showPrice
 * @param fullWidth
 * @returns
 */
const AddCartControl = ({
  onClickMinus,
  onClickPlus,
  price,
  gram,
  unit,
  setGram,
  priceDescription,
  showPriceDescription,
  showPrice = true,
  short,
  canEdit,
  bgColor,
  showButtons,
  unitValue,
  fullWidth
}: AddCartProps) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="mt-3 w-full flex flex-row justify-between items-center select-none">
        <div className="w-full">
          <div
            className={showButtons ? 'w-[100%]' : short ? 'w-[30%]' : 'w-[24%]'}
          >
            <div className="flex flex-row">
              {showButtons && (
                <div
                  onClick={() => {
                    onClickMinus?.()
                  }}
                  className="w-[50px] h-[50px] md:w-[25px] md:h-[25px] items-center flex justify-center rounded-md bg-primary-alpha cursor-pointer"
                >
                  <BiMinus
                    strokeWidth={0.5}
                    size={20}
                    className="text-primary"
                  />
                </div>
              )}
              <div
                className={classNames(
                  'mx-2',
                  'border-[2px]',
                  'rounded-md',
                  'border-tertiary-25',
                  'flex',
                  'flex-row',
                  'justify-between',
                  'text-xs',
                  bgColor || 'bg-tertiary-25',
                  {
                    'h-[50px] md:h-[25px]': true,
                    'w-[calc(100%-100px)] md:w-[90px]': !canEdit && !fullWidth,
                    'w-[65px]': canEdit && !fullWidth
                  }
                )}
              >
                <div className="items-center flex justify-center pl-2 md:w-[50%]">
                  <input
                    className={`duration-300 flex-1 appearance-none w-full bg-inherit text-primary outline-0 text-base md:text-xs`}
                    placeholder={'-'}
                    type={'number'}
                    disabled={canEdit ?? false}
                    value={(gram ?? 0) <= 0 ? '-' : gram}
                    onChange={(e) => {
                      const value = Number(e.target.value)
                      const processedValue = isNaN(value) ? 0 : value
                      if (processedValue > environment.maximumNumberOfCartItem)
                        return
                      setGram(processedValue)
                    }}
                  />
                </div>

                <div className="pr-1 pl-1 items-center flex justify-center text-primary-50 text-base md:text-xs ">
                  {unit}
                </div>
              </div>
              {showButtons && (
                <div
                  onClick={() => onClickPlus?.()}
                  className="w-[50px] h-[50px] md:w-[25px] md:h-[25px]  items-center flex justify-center rounded-md bg-primary-alpha cursor-pointer"
                >
                  <BsPlus
                    strokeWidth={0.5}
                    size={20}
                    className="text-primary"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {showPrice && (
          <div className="pl-1">
            {showPriceDescription && (
              <div className=" text-primary-50 font-small text-xs whitespace-nowrap text-right">
                {priceDescription}
              </div>
            )}

            <span className="text-[14px] md:text-xl xl:text-xl lg:text-xl font-semibold whitespace-nowrap text-right">
              {showPriceDescription && (
                <span className="text-xs font-small mr-1 text-primary-50">
                  {t('from')}
                </span>
              )}
              {price}
            </span>
          </div>
        )}
      </div>

      <QuantityError quantity={gram} unit={unit} unitValue={unitValue} />
    </div>
  )
}

export default AddCartControl
