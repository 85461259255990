import { CalendarProps, Calendar as ReactCalendar } from 'react-calendar'
import { useDropdown } from '../Dropdown'
import { DateFormatter } from '@gruene-brise/data-access/lib/utils'
import CalendarIcon from '@gruene-brise/common-ui/assets/icons/calendar.svg'

interface CustomCalendarProp extends Omit<CalendarProps, 'value'> {
  value?: string | Date
  placeholder?: string
  daySeason: 'start' | 'end'
}

const Calendar: React.FC<CustomCalendarProp> = ({
  value,
  className,
  tileClassName,
  placeholder,
  daySeason,
  onClickDay,
  ...calendarProps
}) => {
  const dropdown = useDropdown()
  const handleOnDayClick = (value: Date, event: any) => {
    const formattedDate = new Date(value)
    const additionalHours =
      daySeason === 'start' ? 0 : daySeason === 'end' ? 23 : 0
    formattedDate.setHours(formattedDate.getHours() + additionalHours)
    onClickDay?.(formattedDate, event)
  }
  return (
    <div className="relative">
      <button
        onClick={() => dropdown.setShowMenu((prev) => !prev)}
        className="w-[125px] bg-tertiary-10 px-4 py-2.5 border border-primary-25 border-solid relative rounded-[6px] flex flex-row items-center justify-between"
      >
        <span
          className={`text-xs ${value ? 'text-primary' : 'text-primary-50'}`}
        >
          {value ? DateFormatter.format(new Date(value), 'date') : placeholder}
        </span>
        <CalendarIcon className="text-primary-25" />
      </button>
      <div
        ref={dropdown.ref}
        className={`${
          !dropdown.showMenu ? 'hidden' : ''
        } w-[350px] absolute top-[105%] z-20 `}
      >
        <ReactCalendar
          className={`bg-tertiary-10 border border-solid border-primary-25 rounded-md shadow-button_sm p-5 ${
            className ?? ''
          }`}
          tileClassName={`text-xs px-1 py-2 text-black ${tileClassName ?? ''}`}
          onClickDay={handleOnDayClick}
          {...calendarProps}
        />
      </div>
    </div>
  )
}

export default Calendar
