import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import FullscreenLoadingIndicator from '../LoadingIndicators/Fullscreen'
import Pagination from '../Pagination'
import TableHead from './TableHead'
import TableRow from './TableRow'
import { ReusableTableProps, SortedColumnProps } from './types'
import {
  Checkout,
  Maybe,
  OrderGroup,
  SortDirection
} from '@gruene-brise/data-access/lib/api/generated'
import TableGroup from './TableGroup'
import { DateFormatter } from '@gruene-brise/data-access/lib/utils'
import CollapsibleRow from './CollapsibleRow'

/**
 * @description Displays a table with the given columns and data.
 */
function ReusableTable<T extends Record<string, any>>({
  columns,
  data,
  groupedData,
  headerClassName,
  rowClassName,
  onRowClick,
  refetchGroupOrderList,
  isLoading,
  specialRowIdentity,
  pagination,
  shouldRowUpdate,
  handleSort,
  collapsibleView
}: ReusableTableProps<T>): JSX.Element {
  const { t } = useTranslation()
  const [sortedColumn, setSortedColumn] = useState<SortedColumnProps>({})

  const [minDate, maxDate] = useMemo(() => {
    let min = ''
    let max = ''

    groupedData?.forEach((obj: OrderGroup) => {
      if (!obj.checkouts || obj.checkouts.length === 0) {
        return
      }

      obj.checkouts.forEach((checkout: Maybe<Checkout>) => {
        if (!min || checkout?.dateOrderedOn < min) {
          min = checkout?.dateOrderedOn
        }
        if (!max || checkout?.dateOrderedOn > max) {
          max = checkout?.dateOrderedOn
        }
      })
    })

    return [min, max]
  }, [groupedData])

  const onSort: typeof handleSort = useCallback(
    <M extends Record<string, any>>(
      field: keyof M,
      direction?: SortDirection
    ) => {
      setSortedColumn({
        field,
        direction
      })
      handleSort?.(field, direction)
    },
    [handleSort]
  )

  return (
    <div className="overflow-x-auto relative rounded-[16px]">
      <FullscreenLoadingIndicator isLoading={isLoading}>
        <table className="table-auto w-full text-md text-left border-collapse !border-b-l-radius-[16px]">
          <TableHead
            {...{ columns, sortedColumn: sortedColumn, headerClassName }}
            handleSort={onSort}
          />
          <tbody>
            {groupedData &&
              groupedData?.length > 0 &&
              groupedData?.map((group) => {
                return (
                  <TableGroup
                    key={group?.id}
                    name={'Bestellungen #' + group.id}
                    data={group.checkouts}
                    groupId={group.id}
                    shouldRowUpdate={shouldRowUpdate}
                    minDate={DateFormatter.format(minDate, 'fullDateYear')}
                    maxDate={DateFormatter.format(maxDate, 'fullDateYear')}
                    refetchGroupOrderList={refetchGroupOrderList}
                    columns={columns}
                    specialRowIdentity={specialRowIdentity}
                    groupStatus={group.groupStatus}
                    rowClassName={rowClassName}
                    onRowClick={onRowClick}
                    collapsibleView={collapsibleView}
                  />
                )
              })}
            {data &&
              data?.length > 0 &&
              !groupedData?.length &&
              data?.map((val) => {
                const PreviewTable = collapsibleView
                return (
                  <React.Fragment
                    key={
                      specialRowIdentity
                        ? val[specialRowIdentity]
                        : val.id || uuidv4()
                    }
                  >
                    <TableRow
                      key={
                        specialRowIdentity
                          ? val[specialRowIdentity]
                          : val.id || uuidv4()
                      }
                      data={val}
                      columns={columns}
                      shouldRowUpdate={shouldRowUpdate?.(val)}
                      onRowClick={onRowClick}
                      rowClassName={rowClassName}
                    />

                    {val.tableUncollapsed && (
                      <CollapsibleRow
                        numberOfColumns={columns.length}
                        isCollapsed={val.tableUncollapsed}
                        collapsibleView={
                          PreviewTable ? <PreviewTable data={val} /> : null
                        }
                      />
                    )}
                  </React.Fragment>
                )
              })}
          </tbody>
        </table>
      </FullscreenLoadingIndicator>
      {data && data?.length === 0 && (
        <div className="w-full h-[350px] flex flex-col justify-center items-center ">
          <p className="text-black text-base font-semibold">
            {t('No results to show')}
          </p>
        </div>
      )}
      {pagination && pagination.pageInfo.total > 0 && (
        <div className="flex justify-between py-4 px-4">
          <div className="font-gellix flex flex-row gap-5 text-md items-center">
            <div className="flex gap-1 items-center text-black">
              <span className="p-2 bg-white rounded-[8px]">
                {t('Overview')}
              </span>
              <span className="p-2">
                {(groupedData?.length || data?.length) ?? 0}
              </span>
            </div>

            <div className="flex gap-1 items-center">
              <div
                className={`${
                  (pagination?.selected ?? 0) <= 0
                    ? 'text-primary-lightgrey'
                    : 'text-black'
                } gap-5`}
              >
                <span className="p-2 bg-white rounded-[8px]">
                  {t('Selected')}
                </span>
                <span className="p-2">{pagination?.selected ?? 0}</span>
              </div>
            </div>
          </div>
          <Pagination {...pagination} />
        </div>
      )}
    </div>
  )
}

export default ReusableTable
