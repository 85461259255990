import { ProductSelection } from '@gruene-brise/data-access/lib/state/useCartState'
import { useTranslation } from 'react-i18next'
import EmptyCartIcon from '../icon/EmptyCartIcon'
import ProductCartItem from './ProductCartItem'

export interface CartDetatilsProps {
  className?: string
  products: ProductSelection
  total?: JSX.Element
  showPriceDescription?: boolean
  showCancel?: boolean
  notEditabled?: boolean
  showButtons?: boolean
  input?: {
    fullWidth?: boolean
  }
}

const CartDetails = ({
  className,
  products,
  total,
  showPriceDescription,
  showCancel,
  notEditabled,
  showButtons,
  input
}: CartDetatilsProps) => {
  const cartItems = Object.keys(products)

  const { t } = useTranslation()

  return (
    <div
      className={`${className} pb-4 overflow-scroll scrollbar-hide bg-white`}
    >
      {cartItems.length <= 0 && (
        <div className="px-5 my-5 w-full items-center justify-center flex flex-col">
          <EmptyCartIcon />

          <div className="mt-4 text-center font-medium font-gellix w-full">
            {t('Your cart is empty!')}
          </div>
        </div>
      )}

      <div className="w-full">
        {cartItems.map((i, idx) => {
          return (
            <ProductCartItem
              key={idx}
              {...products[i]}
              showCancel={showCancel}
              notEditabled={notEditabled}
              showButtons={showButtons}
              showPriceDescription={showPriceDescription}
              input={input}
            />
          )
        })}

        {cartItems.length > 0 && <>{total}</>}
      </div>
    </div>
  )
}

export default CartDetails
