import { useDebounce } from '@gruene-brise/data-access/lib/hooks/useDebounce'
import { useTranslation } from 'react-i18next'

export interface QuantityErrorProps {
  unitValue?: number
  unit?: string
  quantity?: number
}

const QuantityError = ({ unitValue, quantity, unit }: QuantityErrorProps) => {
  const { t } = useTranslation()

  const [debounceQuantity] = useDebounce(quantity, 500)
  const valueIsCorrect = unitValue ? (quantity ?? 0) % unitValue === 0 : true
  const valueIsLessThanMax = quantity ? quantity < (unitValue ?? 0) : true

  const [debounceValueIsCorrect] = useDebounce(valueIsCorrect, 500)
  const [debounceValueIsLessThanMax] = useDebounce(valueIsLessThanMax, 500)

  return debounceQuantity ? (
    <div className="flex flex-col text-left text-sm sm:text-[8px]">
      {!debounceValueIsCorrect && (
        <div className=" text-red-500">
          {t(
            'Quantity should be a multiple of {{unitValue}} e.g {{unitMultiple1}}, {{unitMultiple2}}, {{unitMultiple3}} ... ',
            {
              unitValue: unitValue ?? '',
              unitMultiple1: (unitValue ?? 0) * 1,
              unitMultiple2: (unitValue ?? 0) * 2,
              unitMultiple3: (unitValue ?? 0) * 3
            }
          )}
        </div>
      )}
      {debounceValueIsLessThanMax && (
        <div className=" text-red-500">
          {t('Quantity should be at least the unit value {{unitValue}} ', {
            unitValue: `${unitValue} ${unit}`
          })}
        </div>
      )}
    </div>
  ) : null
}
export default QuantityError
