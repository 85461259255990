import environment from '@gruene-brise/data-access/lib/config/environment'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'

interface SeoProps {
  title?: string
  description?: string
  ignoreTitleTemplate?: boolean
  ogConfig?: {
    imageURL?: string
    title?: string
    description?: string
  }
  noIndex?: boolean
}

const SeoHeader: React.FC<SeoProps> = ({
  title: customTitle,
  description: customDescription,
  ogConfig,
  noIndex = false
}) => {
  const { t } = useTranslation()

  const defaultTitle = t('Bloomwell. The marketplace for Cannabis pharmacies')
  const defaultDescription = t(
    'Bloomwell is the marketplace for Cannabis pharmacies. We offer a wide range of products from the best Cannabis pharmacies in Germany.'
  )

  const title = customTitle || defaultTitle
  const description = customDescription || defaultDescription

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      {/* <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' /> */}
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#233918" />
      {(environment.enableRobotNoIndex === 'true' || noIndex) && (
        <meta name="robots" content="noindex" />
      )}
      <link rel="stylesheet" href="https://static.unzer.com/v1/unzer.css" />
      {/* <script async src='https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js'></script> */}
      {/* <script async type='text/javascript' src='https://js.stripe.com/v2/'></script> */}
      <meta name="google" content="notranslate" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=0, maximum-scale=1"
      />
      <meta property="og:title" content={ogConfig?.title || title} />
      <meta
        property="og:description"
        content={ogConfig?.description || description}
      />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Bloomwell GmbH" />
      {ogConfig?.imageURL && (
        <meta property="og:image" content={ogConfig.imageURL} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@" /> {/* TODO: Add twitter handle */}
      <meta name="twitter:title" content={ogConfig?.title || title} />
      <meta
        name="twitter:description"
        content={ogConfig?.description || description}
      />
      {ogConfig?.imageURL && (
        <meta name="twitter:image" content={ogConfig.imageURL} />
      )}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-221B8PTJVY"
      />
      <meta property='og:title' content={ogConfig?.title || title} />
      <meta property='og:description' content={ogConfig?.description || description} />
      <meta property='og:type' content='website' />
      <meta property='og:site_name' content='Bloomwell GmbH' />
      {ogConfig?.imageURL && <meta property='og:image' content={ogConfig.imageURL} />}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@' /> {/* TODO: Add twitter handle */}
      <meta name='twitter:title' content={ogConfig?.title || title} />
      <meta name='twitter:description' content={ogConfig?.description || description} />
      {ogConfig?.imageURL && <meta name='twitter:image' content={ogConfig.imageURL} />}
      <meta name='google-site-verification' content='YMxhkXqO5RyTzbueV0iIu2PAuqeGNwycLGOiCC8WOIk' />
      <script async src='https://www.googletagmanager.com/gtag/js?id=G-221B8PTJVY' />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-221B8PTJVY');
          `
        }}
      />
    </Head>
  )
}

export default SeoHeader
