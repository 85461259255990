import classNames from 'classnames'
import CheckmarkIcon from '../icon/CheckmarkIcon'
import styles from './form.module.css'
import { v4 as uuidv4 } from 'uuid'
import Spinner from '@gruene-brise/common-ui/assets/icons/spinner.svg'
import { InputProps } from './FormInput'
import { ReactNode } from 'react'

type ValueType = ReactNode

export interface CheckboxProps {
  label?: string | JSX.Element
  labelClassName?: string
  checked?: boolean
  value?: ValueType
  onChange?: (value?: ValueType) => void
  id?: string
  borderColorClassname?: string
  disabled?: boolean
  isLoading?: boolean
}
/**
 * This is a checkbox
 *
 * @param label
 * @param onChange
 * @param value
 * @param id
 * @param value
 * @returns
 */

export const Checkbox: React.FC<CheckboxProps> = ({
  label,
  labelClassName,
  onChange,
  value,
  checked,
  id,
  disabled,
  borderColorClassname = 'primary',
  isLoading
}): JSX.Element => {
  const checkedIcon = checked ? (
    <CheckmarkIcon />
  ) : (
    <CheckmarkIcon stroke="transparent" />
  )
  borderColorClassname = isLoading ? 'none' : borderColorClassname

  return (
    <button
      className="flex cursor-pointer text-left items-center"
      disabled={disabled}
      onClick={
        onChange
          ? (e) => {
              e?.stopPropagation()
              e?.preventDefault?.()
              onChange?.(value)
            }
          : undefined
      }
    >
      <div
        id={id || 'custom-checkbox-'}
        role={'checkbox'}
        aria-checked={checked}
        aria-label="Check"
        className={`transition-all ${classNames({
          '!border-primary-lightgrey': disabled
        })} ${styles['checkbox']} border-${borderColorClassname} ${
          checked && !isLoading && styles['checkbox__checked']
        }`}
      >
        {isLoading ? (
          <Spinner className="w-8 h-8 animate-spin fill-tertiary-50 text-primary-50" />
        ) : (
          checkedIcon
        )}
      </div>
      {label && (
        <div className="pl-2 text-sm">
          <label
            htmlFor={id || 'custom-checkbox-'}
            className={`font-light font-gellix cursor-pointer text-grey text-xs ${
              labelClassName ?? ''
            } ${classNames({
              '!text-primary-25': disabled
            })}`}
          >
            {typeof label === 'string'
              ? label.charAt(0).toUpperCase() + label.slice(1)
              : label}
          </label>
        </div>
      )}
    </button>
  )
}

type CheckboxGroupType = Pick<
  CheckboxProps,
  'borderColorClassname' | 'labelClassName'
> &
  Pick<InputProps, 'errors'> & {
    value: ValueType | ValueType[]
    options: {
      label: string
      value: ValueType
      id?: string
      disabled?: boolean
    }[]
    orientation?: 'vertical' | 'horizontal'
    className?: string
    onChange: (val?: ValueType | ValueType[]) => void
    multiselect?: boolean
  }

export const CheckboxGroup: React.FC<CheckboxGroupType> = ({
  onChange,
  value,
  borderColorClassname,
  labelClassName,
  options,
  orientation = 'vertical',
  className,
  multiselect,
  errors
}) => {
  if (multiselect && !value) {
    value = []
  }
  return (
    <div className="space-y-2">
      <div
        className={`flex gap-4 ${orientation === 'vertical' ? 'flex-col' : 'flex-row'} ${
          className ?? ''
        }`}
      >
        {(options ?? []).map((option) => (
          <Checkbox
            value={option.value}
            key={option.id || uuidv4()}
            disabled={option.disabled}
            checked={
              Array.isArray(value)
                ? value.indexOf(option.value) !== -1
                : value === option.value
            }
            label={option.label}
            id={option.id}
            onChange={(val) => {
              if (val) {
                if (Array.isArray(value)) {
                  let prevValues = [...value]
                  if (prevValues.indexOf(val) !== -1) {
                    prevValues = prevValues.filter((value) => value !== val)
                  } else {
                    prevValues.push(val)
                  }
                  onChange(prevValues)
                } else {
                  onChange(val)
                }
                return
              }
              onChange()
            }}
            labelClassName={labelClassName}
            borderColorClassname={borderColorClassname}
          />
        ))}
      </div>
      <span className=" text-fail text-xs w-full mt-2 font-gellix">
        {errors ?? ''}
      </span>
    </div>
  )
}
