import dynamic from 'next/dynamic'
const AfterUserInitialization = dynamic(
  () => {
    return import('@gruene-brise/common-ui/lib/AfterUserInitialization')
  },
  { ssr: false }
)

import 'react-phone-input-2/lib/style.css'
import { AppProps } from 'next/app'
import { IconContext } from 'react-icons'
import { ChakraProvider } from '@chakra-ui/react'
import SeoHeader from '@gruene-brise/common-ui/lib/SeoHeader'
import '@gruene-brise/common-ui/styles/shared-styles.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import 'react-circular-progressbar/dist/styles.css'
import 'react-calendar/dist/Calendar.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import EnvironmentIndicator from '@gruene-brise/common-ui/lib/EnvironmentIndicator'
import { useRouter } from 'next/router'
import '../styles/styles.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Script from 'next/script'
import { ErrorBoundary } from '@gruene-brise/common-ui/index'
import environment from '@gruene-brise/data-access/lib/config/environment'
import { I18nextProvider } from 'react-i18next'
import i18n from '@gruene-brise/data-access/lib/i18n/i18n'

const queryClient = new QueryClient()

function CustomApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  return (
    <I18nextProvider i18n={i18n}>
      <IconContext.Provider value={{ className: '' }}>
        <QueryClientProvider client={queryClient}>
          <body className=" scrollbar-hide">
            <ChakraProvider>
              <main className="app">
                <EnvironmentIndicator />
                <ErrorBoundary app="webshop">
                  <Component {...pageProps} />
                </ErrorBoundary>
              </main>
            </ChakraProvider>
            <SeoHeader />
          </body>
          <ToastContainer position="top-right" />

          <AfterUserInitialization pageProps={pageProps} />

          {/* Hack to remove chat bot for website */}
          {router.pathname !== '/website-navbar' && (
            <Script
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src="//js-eu1.hs-scripts.com/27088937.js"
            />
          )}

          {environment.showMarkerIO && (
            <Script type="text/javascript" async defer src="/marker.js" />
          )}

          <Script
            data-cbid="a7b1fae3-de9b-4dc0-b97b-db7f7adc1b50"
            data-blockingmode="auto"
            type="text/javascript"
            async
            defer
            src="https://consent.cookiebot.com/uc.js"
          />

          <Script src="https://static.unzer.com/v1/unzer.js" />
        </QueryClientProvider>
      </IconContext.Provider>
    </I18nextProvider>
  )
}

export default CustomApp
