import { isNull, isUndefined } from 'lodash'
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query'
import { AvailableOffersInput } from '../api/generated'
import { useAuthState } from '../state/useAuthState'
import { useGraphql } from './useGraphql'
import useGraphqlError from './useGraphqlError'

export interface useInfinitePublicAvailableOfferProps {
  input: AvailableOffersInput
  option?: UseInfiniteQueryOptions
  limit?: number
  handleError?: ((err: unknown) => void) | undefined
  disable?: boolean
}

/**
 *
 * Offer List manager
 *
 * @param input
 * @param option
 * @param limit
 * @handleError handleError
 * @returns
 */
const useInfinitePublicAvailableOffer = ({
  input,
  option,
  limit,
  handleError,
  disable
}: useInfinitePublicAvailableOfferProps) => {
  const graphql = useGraphql()
  const { showError } = useGraphqlError()

  const query = useInfiniteQuery(
    ['product-offer', input],
    ({ pageParam }) => {
      return graphql
        .availableOffers({
          input: {
            products: input.products,
            filter: {
              pagination: { cursor: pageParam, pageSize: limit ?? 20 },
              ...input.filter
            }
          }
        })
        .then(({ availableOffers }) => availableOffers)
    },
    {
      getNextPageParam: (prev) => {
        return isNull(prev.pageInfo.nextCursor)
          ? undefined
          : prev.pageInfo.nextCursor
      },
      onError: handleError ?? showError,
      enabled: input.products.length > 0 && !disable,
      retry: false,
      ...(option as any)
    }
  )
  return query
}

export default useInfinitePublicAvailableOffer
