import React from 'react'
import Spinner from '@gruene-brise/common-ui/assets/icons/spinner.svg'
import { ClipLoader } from 'react-spinners'
interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  isLoading?: boolean
  loadingView?: React.ReactNode
  wrapperClassName?: string
}

function FullscreenLoadingIndicator({
  isLoading,
  children,
  loadingView,
  wrapperClassName,
  ...rest
}: Props) {
  const loadingArea = (
    <div
      {...rest}
      className={`${
        wrapperClassName ??
        'fixed top-0 left-0 bottom-0 min-h-[350px] h-full w-full bg-[#00000036] z-[2000] '
      } ${rest.className}`}
    >
      {loadingView || (
        <div className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 ">
          <Spinner className="w-8 h-8 animate-spin fill-tertiary-50 text-primary-50" />
          <span className="sr-only">Loading...</span>
        </div>
      )}
    </div>
  )

  return (
    <div className="relative items-center block w-full">
      {children}
      <div className={`w-full h-full ${!isLoading ? 'hidden' : 'block'}`}>
        {loadingArea}
      </div>
    </div>
  )
}

export const ButtonLoadingSpinner: React.FC<
  Pick<Props, 'isLoading' | 'className'>
> = ({ isLoading, className }) => {
  return (
    <ClipLoader
      size={'15px'}
      color=""
      loading={isLoading}
      className={className}
    />
  )
}

export default FullscreenLoadingIndicator
