import 'dotenv/config'

export enum Environment {
  Local = 'local',
  Dev = 'dev',
  Staging = 'staging',
  Production = 'production'
}

const defaultEnvironment = {
  allowedRedirectDomainsRegex:
    '^(https\:\/\/.*(algea-care\.rocks|algeacare.com|bloomwell\.de|gruenebrise\.dev|gruenebrise\.de)($|\/.*)|\/.*)',
  useSentry: true,
  maximumPageSize: 50,
  maximumNumberOfCartItem: 1000000,
  minChildrenAge: 18,
  maxPrescriptionValidityYear: 7,
  maxPrescriptionValidityDays: 0,
  release: process.env['NEXT_PUBLIC_RELEASE'],
  showMarkerIO: false,
  country: 'DE',
  maxOldAge: 120,
  tooManyOutstandingOrders: 1,
  unzerCustomerInvoiceLink: `https://customer.paylater.unzer.com/consumer/authentication/start`,
  tiktokPixelId: 'CJ90I6BC77U6CCLDSV00',
  events: {
    AddToCart: 'AddToCart',
    InitializePurchase: 'InitializePurchase',
    Purchase: 'Purchase'
  },
  enableRobotNoIndex: process.env['ENABLE_ROBOT_NOINDEX'] ?? 'false'
}

const environment = (function () {
  const devEnvironment = {
    ...defaultEnvironment,
    apiUrl: 'https://api.gruenebrise.dev',
    threatMatrix: '',
    forceLanguage: 'de',
    adminAuth0ClientId: '8TAxz3axHDRc9sKru4TCaHDjb9ZzBL6z',
    webshopAuth0ClientId: 'OfoC05bizl4xWYandFaGBJarZFGtKxDq',
    auth0Issuer: 'https://algeacare.eu.auth0.com',
    auth0Audience: 'https://api.gruenebrise.dev',
    algeaLoginUrl: 'https://identity.staging.algeacare.rocks/',
    auth0Scope: 'openid profile email',
    auth0State: 'STATE',
    auth0RedirectUrl: 'https://api.gruenebrise.dev/auth/admin/callback',
    auth0ResponseType: 'code',
    environment: Environment.Dev,
    webshopBaseUrl: 'https://shop.gruenebrise.dev/',
    adminBaseUrl: 'https://admin.gruenebrise.dev/',
    supportUrl:
      'https://app-eu1.hubspot.com/contacts/27088937/objects/0-5/views/all/list',
    websiteUrl: 'https://gruenebrise.dev/',
    showMarkerIO: true
  }

  const localEnvironment = {
    ...devEnvironment,
    allowedRedirectDomainsRegex: '^(http\:\/\/(localhost)($|\/.*)|\/.*)',
    // NOTE YOU CANNOT USE STAGING LOCALLY FOR AUTHENTICATION WE ARE NOT SURE WHY, MIGHT BE A BACKEND NETWORK CONFIG
    // apiUrl: 'https://api.gruenebrise.dev',
    apiUrl: 'http://localhost:8001',
    // NEVER HAVE THESE URLS AS THE SAME.... trust me!
    // webshopBaseUrl: "https://gsus.serveo.net/",
    webshopBaseUrl: 'http://localhost:4201/',
    adminBaseUrl: 'http://localhost:4200/',
    adminAuth0ClientId: 'jVC5cJkmktdiRqnl1HDtR687qkioaQUb',
    webshopAuth0ClientId: 'jVC5cJkmktdiRqnl1HDtR687qkioaQUb',
    useSentry: false
  }

  const stagingEnvironment = {
    ...defaultEnvironment,
    apiUrl: 'https://api.staging.gruenebrise.dev',
    forceLanguage: 'de',
    adminAuth0ClientId: 'i8B4STPVMwEZ2s9hw4IkpZFlue8Ftalz',
    webshopAuth0ClientId: 'BgqRgHAll1DT86oiZb0Cd1x4oBtRfeYJ',
    auth0Issuer: 'https://algeacare-staging.eu.auth0.com',
    auth0Audience: 'https://api.staging.gruenebrise.dev',
    algeaLoginUrl: 'https://identity.staging.algeacare.rocks/',
    auth0Scope: 'openid profile email',
    auth0State: 'STATE',
    auth0RedirectUrl: 'https://api.staging.gruenebrise.dev/auth/admin/callback',
    auth0ResponseType: 'code',
    environment: Environment.Staging,
    webshopBaseUrl: 'https://shop.staging.gruenebrise.dev/',
    adminBaseUrl: 'https://admin.staging.gruenebrise.dev/',
    supportUrl:
      'https://app-eu1.hubspot.com/contacts/27088937/objects/0-5/views/all/list',
    websiteUrl: 'https://gruenebrise.dev/',
    showMarkerIO: true
  }

  // TODO
  const productionEnvironment = {
    ...defaultEnvironment,
    forceLanguage: null,
    apiUrl: 'https://api.gruenebrise.de',
    adminAuth0ClientId: 'Kxs7ELL2aINdSWD6i8G18VTyGJ1QN0Wo',
    webshopAuth0ClientId: 'qW0WoGPRgohjRzKGUVuwlptEy1Dk7YJO',
    auth0Issuer: 'https://algeacare-prod.eu.auth0.com',
    auth0Audience: 'https://api.gruenebrise.de',
    auth0Scope: 'openid profile email',
    auth0State: 'STATE',
    auth0RedirectUrl: 'https://api.gruenebrise.de/auth/admin/callback',
    algeaLoginUrl: 'https://identity.algea-care.com/',
    auth0ResponseType: 'code',
    environment: Environment.Production,
    webshopBaseUrl: 'https://shop.gruenebrise.de/',
    adminBaseUrl: 'https://admin.gruenebrise.de/',
    supportUrl:
      'https://app-eu1.hubspot.com/contacts/27088937/objects/0-5/views/all/list',
    websiteUrl: 'https://www.gruenebrise.de/'
  }

  return {
    [Environment.Local]: localEnvironment,
    [Environment.Dev]: devEnvironment,
    [Environment.Staging]: stagingEnvironment,
    [Environment.Production]: productionEnvironment
  }[getEnvironment()]
})()

export function getEnvironment() {
  const fromEnv = process.env['NEXT_PUBLIC_ENVIRONMENT']

  if (typeof window === 'undefined' || !!fromEnv) {
    switch (fromEnv) {
      case Environment.Local:
        return Environment.Local
      case Environment.Dev:
        return Environment.Dev
      case Environment.Staging:
        return Environment.Staging
      case Environment.Production:
        return Environment.Production
      default:
        return Environment.Dev
    }
  }

  // if trying to run locally on ssh tunnel remove all
  // this and return local always, we will fix it with doppler
  // envs for the front end, or make sure you set NEXT_PUBLIC_ENVIRONMENT
  // in the env file

  const origin = window.location.origin

  if (origin.includes('localhost')) {
    return Environment.Local
  }

  if (origin.includes('staging')) {
    return Environment.Staging
  }

  if (origin.includes('gruenebrise.dev')) {
    return Environment.Dev
  }

  return Environment.Production
}

export const enableEsignature = false

export default environment
