import { WebshopProductFilterFields } from '@gruene-brise/data-access/lib/types'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import { FormProps } from '.'
import { FilterOptionProps } from '@gruene-brise/data-access/lib/utils'
import {
  ProductAvailabilityOptions,
  ProductCategory
} from '@gruene-brise/data-access/lib/api/generated'

enum IrradiationEnum {
  Gamma = 'Gamma',
  EBeam = 'E-Beam',
  NoIrradiation = 'Unbestrahlt'
}

export const useProductFilterOptions = () => {
  const { t } = useTranslation()
  const [filterOptions, setFilterOptions] = useState<
    FilterOptionProps<WebshopProductFilterFields, FormProps>
  >({
    category: {
      filterFactory: 'isIn',
      field: 'category',
      options: [
        {
          label: t('Capsule'),
          value: ProductCategory.Capsule,
          id: uuidv4()
        },
        {
          label: t('Extract'),
          value: ProductCategory.Extract,
          id: uuidv4()
        },
        {
          label: t('Flower'),
          value: ProductCategory.Flower,
          id: uuidv4()
        }
      ]
    },

    availability: {
      filterFactory: 'isIn',
      field: 'availability',
      options: [
        {
          label: t('Unavailable'),
          value: ProductAvailabilityOptions.Unavailable,
          id: uuidv4()
        },
        {
          label: t('Immediately available'),
          value: ProductAvailabilityOptions.ImmediatelyAvailable,
          id: uuidv4()
        },
        {
          label: t('Available soon'),
          value: ProductAvailabilityOptions.AvailableIn,
          id: uuidv4()
        }
      ]
    },

    genetics: {
      options: [
        {
          label: t('indica'),
          value: 'Indica',
          id: uuidv4()
        },
        {
          label: t('sativa'),
          value: 'Sativa',
          id: uuidv4()
        },
        {
          label: t('hybrid'),
          value: 'Hybrid',
          id: uuidv4()
        }
      ],
      filterFactory: 'isIn',
      field: 'genetics'
    },
    thcFrom: {
      options: [
        // Range & input
        {
          label: t('Min-Max'),
          value: 'min-max1',
          id: uuidv4()
        },
        {
          label: t('Min-Max'),
          value: 'min-max2',
          id: uuidv4()
        }
      ],
      filterFactory: 'isGreaterThanOrEqual',
      field: 'thcPercentageFrom'
    },
    thcTo: {
      options: [
        // Range & input
        {
          label: t('Min-Max'),
          value: 'min-max1',
          id: uuidv4()
        },
        {
          label: t('Min-Max'),
          value: 'min-max2',
          id: uuidv4()
        }
      ],
      filterFactory: 'isLessThanOrEqual',
      field: 'thcPercentageTo'
    },
    cbdFrom: {
      options: [
        // Range & input
        {
          label: t('Min-Max'),
          value: 'min-max3',
          id: uuidv4()
        },
        {
          label: t('Min-Max'),
          value: 'min-max4',
          id: uuidv4()
        }
      ],
      filterFactory: 'isGreaterThanOrEqual',
      field: 'cbdPercentageFrom'
    },
    cbdTo: {
      options: [
        // Range & input
        {
          label: t('Min-Max'),
          value: 'min-max3',
          id: uuidv4()
        },
        {
          label: t('Min-Max'),
          value: 'min-max4',
          id: uuidv4()
        }
      ],
      filterFactory: 'isLessThanOrEqual',
      field: 'cbdPercentageTo'
    },
    manufacturer: {
      options: [
        // dropdown
        {
          label: t('Manufacturer name'),
          value: 'name1',
          id: uuidv4()
        },
        {
          label: t('Manufacturer name'),
          value: 'name2',
          id: uuidv4()
        },
        {
          label: t('Manufacturer name'),
          value: 'name3',
          id: uuidv4()
        },
        {
          label: t('Manufacturer name'),
          value: 'name4',
          id: uuidv4()
        }
      ],
      filterFactory: 'isIn',
      field: 'manufacturer'
    },
    origin: {
      options: [
        // dropdown
        {
          label: t('Origin name'),
          value: 'name1',
          id: uuidv4()
        },
        {
          label: t('Origin name'),
          value: 'name2',
          id: uuidv4()
        },
        {
          label: t('Origin name'),
          value: 'name3',
          id: uuidv4()
        },
        {
          label: t('Origin name'),
          value: 'name4',
          id: uuidv4()
        }
      ],
      filterFactory: 'isIn',
      field: 'origin'
    },
    irradiation: {
      options: [
        // dropdown
        {
          label: t('Gamma'),
          value: IrradiationEnum.Gamma,
          id: uuidv4()
        },
        {
          label: t('E-Beam'),
          value: IrradiationEnum.EBeam,
          id: uuidv4()
        },
        {
          label: t('No irradiation'),
          value: IrradiationEnum.NoIrradiation,
          id: uuidv4()
        }
      ],
      filterFactory: 'isIn',
      field: 'irradiation'
    },
    price_from: {
      filterFactory: 'isGreaterThanOrEqual',
      field: 'startingPrice',
      valueOverwrite(value) {
        return (+value * 100).toFixed(0)
      }
    },
    price_to: {
      filterFactory: 'isLessThanOrEqual',
      field: 'startingPrice',
      valueOverwrite(value) {
        return (+value * 100).toFixed(0)
      }
    }
  })

  return { filterOptions, setFilterOptions }
}
