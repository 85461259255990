import { useTranslation } from 'react-i18next'
import ShoppingCart from '../icon/ShoppingCart'
import UserIcon from '../icon/UserIcon'
import { useAuthState } from '@gruene-brise/data-access/lib/state/useAuthState'
import { isUndefined } from 'lodash'
import { useCartState } from '@gruene-brise/data-access/lib/state/useCartState'
import { useRouter } from 'next/router'
import ReactCountryFlag from 'react-country-flag'

export interface WebshopHeaderMobileProps {
  onSearch?(e: string): void
  onClickNavItem?(e: number): void
  onClickFlag?(): void
  menu: { jsx: JSX.Element; onPress?: () => void; link?: string }[]
  isActiveIndex?: number
  isDark?: boolean
  isEnglish?: boolean
}

const WebshopHeaderMobile = ({
  menu,
  isActiveIndex,
  onClickNavItem,
  isDark,
  onClickFlag,
  isEnglish
}: WebshopHeaderMobileProps) => {
  const { t } = useTranslation()
  const { isCartOpen, setCartOpen } = useCartState()
  const { push } = useRouter()
  const bgColor = isDark ? 'bg-primary' : 'bg-tertiary-10'
  const textColor = isDark ? 'text-primary-25' : 'text-primary'
  const { userInfo } = useAuthState()

  return (
    <>
      <div
        className={`w-screen fixed top-[50px] sm:top-[110px] lg:top-[60px] mt-1 border-t-[2px] border-primary-alpha h-screen flex-col  ${bgColor} overflow-scroll scrollbar-hide flex xs:flex sm:flex md:flex lg:hidden xl:hidden`}
        style={{ zIndex: 300000 }}
      >
        <div className="w-full flex flex-row gap-3 justify-between">
          <div className="flex px-5 flex-row w-full gap-5 pt-7 pl-8">
            <div className="flex flex-row gap-2 pb-5 font-medium">
              <UserIcon />
              {isUndefined(userInfo) ? (
                <div
                  className="text-primary-50"
                  onClick={() => push('/signin')}
                >
                  {t('Login')}
                </div>
              ) : (
                <div
                  className="text-primary-50 whitespace-nowrap"
                  onClick={() => push('/account/personal-details')}
                >
                  {t('My account')}
                </div>
              )}
            </div>
          </div>
          <div
            className="flex flex-row items-center gap-2 pr-8 cursor-pointer"
            onClick={() => {
              onClickNavItem?.(1)
              setCartOpen(!isCartOpen)
            }}
          >
            <ShoppingCart color="#919C8B" />
            <div className="text-primary-50 text-md font-medium pt-2">
              {t('Cart')}
            </div>
          </div>
        </div>

        <div className="ml-3">
          {menu.map((item, index) => (
            <div
              className={`
      ${textColor} font-medium mx-5 m px-4 py-3 text-lg ${
                index === isActiveIndex ? ' bg-[rgba(255,151,137,0.1)]' : ''
              } transition-all duration-700`}
              onClick={() => {
                onClickNavItem?.(index)
                if (item.onPress) {
                  item.onPress()
                  return
                }
                if (item.link) {
                  window.open(item.link)
                }
              }}
            >
              {item.jsx}
            </div>
          ))}
          <div
            className="mx-5 m px-4 py-3 sm:hidden"
            onClick={() => onClickFlag?.()}
          >
            <ReactCountryFlag
              countryCode={isEnglish ? 'GB' : 'DE'}
              svg
              style={{
                width: 15
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default WebshopHeaderMobile
