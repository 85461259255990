import { ExtendedProductProps, ProductMetaFieldNames } from '../types'

export const getMetaFieldValue = (
  product: ExtendedProductProps,
  field: ProductMetaFieldNames
) => {
  const index = product?.metaFields?.findIndex(
    (metafield) => metafield.name === field
  )
  return product?.metaFields?.[index]?.value
}

export function replaceEmptyStringsWithNullValue(
  data: any,
  excludeKeys?: string[]
) {
  Object.keys(data).forEach((key) => {
    if (excludeKeys?.indexOf(key) !== -1) {
      return
    }
    const value = data[key]
    if (Object.prototype.toString.call(value) === '[object Object]') {
      replaceEmptyStringsWithNullValue(value, excludeKeys)
    }
    if (data[key] === '') {
      data[key] = null
    }
  })
}

export function getPercentagesOrEmpty(
  percentageFrom: string,
  percentageTo: string
): string {
  if (
    [percentageFrom, percentageTo].every((percentage) => percentage === '0')
  ) {
    return '-'
  }

  return `${percentageFrom.indexOf('%') > -1 ? percentageFrom : percentageFrom.concat('%')} - ${percentageTo.indexOf('%') > -1 ? percentageTo : percentageTo.concat('%')}`
}

export function getPercentageOrZero(
  percentage: number | null | undefined
): string {
  return `${percentage || '0'}`
}
