import Carousel, { CarouselProps } from 'react-multi-carousel'
import CaretRight from '@gruene-brise/common-ui/assets/icons/caret-right.svg'
import Caretleft from '@gruene-brise/common-ui/assets/icons/caret-left.svg'
import styles from './carousel.module.css'

const CustomRightArrow = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide, slidesToShow, totalItems }
  } = rest
  return (
    <div className="absolute top-1/2 left-0 right-0 -translate-y-1/2 flex justify-center">
      <div className="flex justify-between w-full max-w-[240px]">
        <button
          onClick={previous}
          disabled={currentSlide === 0}
          className={`${styles['button']}`}
        >
          <Caretleft className="text-inherit" />
        </button>
        <button
          disabled={totalItems - slidesToShow - currentSlide === 0}
          onClick={next}
          className={`${styles['button']}`}
        >
          <CaretRight className="text-inherit" />
        </button>
      </div>
    </div>
  )
}

export const CustomCarousel = ({
  children,
  className,
  ...carouselProps
}: React.PropsWithChildren<Partial<CarouselProps>>) => {
  const options = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  }

  return (
    <div className={`relative ${className}`}>
      <Carousel
        renderButtonGroupOutside={true}
        customButtonGroup={<CustomRightArrow />}
        arrows={false}
        {...carouselProps}
        showDots={false}
        responsive={options}
      >
        {children}
      </Carousel>
    </div>
  )
}
