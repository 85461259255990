import { MetaField, PublicProduct } from '../api/generated'
import { NumberFormatter } from './number-formatter'

export function getThcRange(unit: string, product?: PublicProduct): string {
  const filteredReferenceValues = [
    product?.thcQuantityFrom,
    product?.thcQuantityTo
  ].filter(
    (value) => value !== undefined && value !== null && !Number.isNaN(value)
  ) as number[]
  return getNumberRange(unit, filteredReferenceValues)
}

export function getCbdRange(unit: string, product?: PublicProduct): string {
  const filteredReferenceValues = [
    product?.cbdQuantityFrom,
    product?.cbdQuantityTo
  ].filter(
    (value) => value !== undefined && value !== null && !Number.isNaN(value)
  ) as number[]
  return getNumberRange(unit, filteredReferenceValues)
}

export function getNumberRange(unit: string, values: number[]): string {
  const filteredReferenceValues = values.filter(
    (value) => value !== undefined && value !== null && !Number.isNaN(value)
  )

  // we need to overwrite the locale here, because the number formatter uses the i18n language
  // but the backend sends the numbers in en-GB format
  const min = Math.trunc(
    Number(
      NumberFormatter.formatPrecision(
        Math.min(...filteredReferenceValues),
        2,
        'en-GB'
      )
    )
  )
  const max = Math.trunc(
    Number(
      NumberFormatter.formatPrecision(
        Math.max(...filteredReferenceValues),
        2,
        'en-GB'
      )
    )
  )

  const checkedMin =
    Number.isNaN(min) && Number.isNaN(max)
      ? ''
      : Number.isNaN(min)
        ? ''
        : unit === 'gram'
          ? `${min}%`
          : `${min}mg/ml`
  const checkedMax =
    Number.isNaN(min) && Number.isNaN(max)
      ? ''
      : Number.isNaN(max)
        ? ''
        : unit === 'gram'
          ? `${max}%`
          : `${max}mg/ml`

  if (min === max) {
    return unit === 'gram' ? `${min}%` : `${min}mg/ml`
  }

  return `${checkedMin} - ${checkedMax}`
}
