import { useQuery } from 'react-query'
import {
  CustomShippingDetails,
  DeliveryOption,
  DeliveryOptionType,
  FilterPharmacyInput,
  Maybe,
  Pharmacy,
  PharmacyDetailInput,
  PharmacyDetailResponse
} from '../api/generated'
import { useGraphql } from './useGraphql'
import useGraphqlError from './useGraphqlError'

export interface usePharmacyFilter {
  input: FilterPharmacyInput
  handleError?: ((err: unknown) => void) | undefined
}

const useFilterPharmacies = ({ input, handleError }: usePharmacyFilter) => {
  const graphql = useGraphql()
  const { showError } = useGraphqlError()

  const query = useQuery(
    ['pharmacies', input],
    async () => {
      return graphql
        .filterPharmacies({
          input: input
        })
        .then(({ filterPharmacies }) => filterPharmacies)
    },
    {
      onError: handleError ?? showError,
      retry(failureCount, error) {
        return (error as any)?.response?.status !== 401
      }
    }
  )
  return query
}

type usePharmacyDetailProps = Pick<usePharmacyFilter, 'handleError'> &
  PharmacyDetailInput

export const usePharmacyDetail = ({
  id,
  handleError
}: usePharmacyDetailProps) => {
  const graphql = useGraphql()
  const { showError } = useGraphqlError()

  const query = useQuery(
    ['pharmacies', id],
    async () => {
      if (id) {
        return graphql
          .pharmacyDetail({
            input: { id }
          })
          .then(({ pharmacyDetail }) => pharmacyDetail)
      }
      return {} as PharmacyDetailResponse
    },
    {
      onError: handleError ?? showError,
      retry: false
    }
  )
  return query
}

type useUserPharmacyDetailProps = Pick<usePharmacyFilter, 'handleError'>

export const useUserPharmacy = ({
  handleError
}: useUserPharmacyDetailProps) => {
  const graphql = useGraphql()
  const { showError } = useGraphqlError()

  const query = useQuery(
    [`userPharmacy-${new Date().toISOString()}`],
    async () => {
      return graphql
        .getUserPharmacy({
          input: { default: true }
        })
        .then(({ getUserPharmacy }) => getUserPharmacy)
    },
    {
      onError: handleError ?? showError
    }
  )
  return query
}

export const useGetAvailableDeliveryOptions = ({
  handleError,
  pharmacyId,
  enabled = false,
  subTotal
}: {
  handleError?: ((err: unknown) => void) | undefined
  pharmacyId: Pharmacy['id']
  enabled?: boolean
  subTotal?: number
  customShippingDetails?: Maybe<CustomShippingDetails>
}) => {
  const graphql = useGraphql()
  const { showError } = useGraphqlError()
  const query = useQuery(
    ['availableDeliveryOptions', pharmacyId],
    async () => {
      return graphql
        .getAvailableDeliveryOptions({
          input: { pharmacyId, subTotal }
        })
        .then(({ getAvailableDeliveryOptions }) =>
          getAvailableDeliveryOptions?.results
            ?.filter((result) => result !== null)
            ?.sort((a, b) => a?.id - b?.id)
        )
    },
    {
      onError: handleError ?? showError,
      enabled
    }
  )

  return query
}

export default useFilterPharmacies
