import { useCartState } from '@gruene-brise/data-access/lib/state/useCartState'
import { uniq } from 'lodash'
import ShoppingCart from '../icon/ShoppingCart'

const ShoppingCartIcon = () => {
  const { isCartOpen, setCartOpen, products } = useCartState()
  const uniqProduct = uniq(Object.keys(products))

  return (
    <div
      className={`rounded-lg hover:bg-white duration-1000 cursor-pointer transition-all items-center justify-center flex p-1 ${
        isCartOpen ? 'bg-white' : 'bg-transparent'
      }`}
      onClick={() => {
        setCartOpen(!isCartOpen)
      }}
    >
      <ShoppingCart color="#919C8B" />
      {uniqProduct.length > 0 && (
        <div className="min-w-[20px] h-[20px] border-2 border-primary-5 px-1 text-center text-white font-gellix text-[10px] absolute rounded-full bg-secondary -mt-5 ml-6">
          {uniqProduct.length}
        </div>
      )}
    </div>
  )
}

export default ShoppingCartIcon
